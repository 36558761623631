import { template as template_5197ff235bd34d2e93999b1643571945 } from "@ember/template-compiler";
const FKLabel = template_5197ff235bd34d2e93999b1643571945(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
