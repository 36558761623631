import { template as template_80e76647f35144869e73c817ea3d1566 } from "@ember/template-compiler";
const FKControlMenuContainer = template_80e76647f35144869e73c817ea3d1566(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
